import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Loader, ErrorFallback, TextInput } from "smart-react";
import { Card, CardHeader, CardBody } from "@progress/kendo-react-layout";
import { Error } from "@progress/kendo-react-labels";
import { Link } from "react-router-dom";
import { Button } from "@progress/kendo-react-buttons";
import { buildNotification, GenerateNotification } from "smart-react";
import {
  createCommand,
  updateCommand,
  listLoadCommand,
} from "../Services/LoadCommandService";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, Field } from "@progress/kendo-react-form";
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from "../../../constants/eventDataTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import CommandEditor from "../../Core/Components/CommandEditor/CommandEditor";
import { useAuth } from "../../Core/Context/AuthContext";
import { SplitButton, SplitButtonItem } from "@progress/kendo-react-buttons";
import TenantDropdown from "../../Core/Components/TenantDropdown/TenantDropdown";
import {
  ValidatePermissions,
  findTenant,
  findTenantById,
} from "../../../Utils/Auth/ValidatePermissions";
import { isValidate, isFromValid } from "../../../Utils/Forms/Form";
import {
  CREATE_MESSAGE,
  UPDATE_MESSAGE,
  ERROR_MESSAGE,
} from "../../../constants/notificationMessages";
/**
 * Add New Test main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The Add New Test component.
 */
const AddLCS = () => {
  const navigate = useNavigate();
  const { tenantID, getTokensFromStorage } = useAuth(); // Get tenantID using Auth Context
  const [detailsDataModified, setDetailsDataModified] = React.useState(false);
  const [tenant, setTenant] = React.useState(findTenantById(tenantID));
  const [loading, setLoading] = React.useState(false);
  const [isEditor, setIsEditor] = React.useState(false);
  const [validateForm, setValidateForm] = React.useState(false);
  const [formData, setFormData] = React.useState({
    tenant_id: tenantID,
    TenantName: tenant?.TenantName,
    les_cmd_id: "",
    cust_lvl: "-1",
    syntax: "",
    grp_nam: "",
  });
  const requiredFields = ["les_cmd_id", "syntax", "TenantName"];
  const [editorField, setEditorField] = React.useState({
    name: "",
    value: "",
    label: "",
  });
  const { state } = useLocation();
  const handleSaveSplit = (e) => {
    const { itemIndex } = e;
    switch (itemIndex) {
      case 0: {
        onSave({ isExit: true });
        break;
      }
      case 1:
        onSave({ isContinue: true });
      default: {
      }
    }
  };

  React.useEffect(() => {
    if (state) {
      const { accessToken } = getTokensFromStorage();
      const { dataSet } = state;
      (async () => {
        setLoading(true);
        const response = await listLoadCommand({
          accessToken: accessToken,
          limit: "50",
          offset: 1,
          filter: [
            {
              ColumnName: "les_cmd_id",
              Op: "eq",
              ColumnValue: dataSet?.les_cmd_id,
            },
          ],
          isServiceWorker: false,
        });
        const testData = response.loadCommands[0];
        setFormData(testData);
        setLoading(false);
      })();
      setFormData({ ...dataSet });
      setTenant(findTenant(dataSet));
      if (ValidatePermissions(dataSet)) {
        setDetailsDataModified(true);
      }
    } else {
      setDetailsDataModified(true);
    }
  }, [state]);

  /**
   * on update the tenantID update the formData
   */
  React.useEffect(() => {
    if (!state?.edit) {
      let tempTenant = findTenantById(tenantID);
      setTenant(tempTenant);
      setFormData((prevState) => ({
        ...prevState,
        tenant_id: tenantID,
        TenantName: tempTenant?.TenantName ? tempTenant?.TenantName : "",
      }));
    }
  }, [tenantID]);

  /**
   * handle Input Change
   * @e object
   */
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === "checkbox" ? (checked ? 1 : 0) : value;
    setFormData((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
  };

  /**
   * onSave
   * submit the test data to api
   */
  const onSave = async ({ isExit = false }) => {
    setValidateForm(true);
    if (!isFromValid({ formData, requiredFields })) {
      return;
    }
    setLoading(true);
    let notificationMetaData = {};
    const data = {
      ...formData,
      les_cmd_id: state?.edit
        ? formData.les_cmd_id
        : tenant?.OrganizationPrefix + formData.les_cmd_id,
      uc_ossi_disable_flg: formData?.uc_ossi_disable_flg ? 1 : 0,
    };
    let response;
    let method;
    if (state?.edit) {
      method = updateCommand;
      notificationMetaData = UPDATE_MESSAGE;
    } else {
      method = createCommand;
      notificationMetaData = CREATE_MESSAGE;
      setFormData({
        tenant_id: tenantID,
        TenantName: tenant?.TenantName,
        les_cmd_id: "",
        cust_lvl: "-1",
        syntax: "",
        grp_nam: "",
      });
    }

    try {
      setValidateForm(false);
      response = await method({
        data,
        moduleName: "lcs",
        isServiceWorker: false,
      });

      if (response.Message) {
        GenerateNotification(
          buildNotification({
            title: ERROR_MESSAGE?.title,
            description: response.Message,
            style: ERROR_MESSAGE?.style,
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
        );
      } else {
        GenerateNotification(
          buildNotification(notificationMetaData),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
        );
        if (isExit) {
          navigate("/autest/load-command-screen");
        }
      }
    } catch (error) {
      GenerateNotification(
        buildNotification(ERROR_MESSAGE),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
      );
    } finally {
      setLoading(false);
    }
  };

  /**
   * onExit
   * back to tests listing screen
   */
  const onExit = () => {
    navigate("/autest/load-command-screen");
  };
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className="container mx-auto">
        <React.Suspense fallback={<Loader />}>
          {loading && <Loader />}
          <>
            <div className={`${isEditor ? "" : "k-d-none"}`}>
              <CommandEditor
                label={"Syntax"}
                renderTypes={!detailsDataModified ? "ReadOnly" : ""}
                validateForm={validateForm}
                formData={formData}
                setFormData={setFormData}
                detailsDataModified={detailsDataModified}
                isEditor={isEditor}
                setIsEditor={setIsEditor}
                editorField={editorField}
                setEditorField={setEditorField}
                smHeight={"100px"}
                lgHeight={"70vh"}
              />
            </div>
            <div
              className={`add-test main-page-section add-page-section ${!isEditor ? "" : "k-d-none"}`}
            >
              <Card className="tophead">
                <CardHeader>
                  <div className="action-buttons-container">
                    <Link
                      className="action-icon-wrapper"
                      to={`/autest/load-command-screen`}
                    >
                      <FontAwesomeIcon className="k-mr-2" icon={faArrowLeft} />
                    </Link>
                    <p className="k-align-items-center k-d-flex k-mb-0 display-text">
                      Command:{" "}
                      {state?.edit
                        ? formData?.les_cmd_id
                        : (tenant?.OrganizationPrefix ?? "") +
                          formData?.les_cmd_id}
                    </p>
                  </div>
                </CardHeader>
              </Card>
              <Card className="k-mt-3 detail-card">
                <CardHeader>
                  <h6 className="card-title-secondary">Details</h6>
                </CardHeader>
                <CardBody className="detail-card-body">
                  <div>
                    <Form
                      onSubmit={onSave}
                      render={(formRenderProps) => (
                        <div className="card-content">
                          <div className="k-d-flex single-field-row">
                            <div
                              className={`field-wrapper ${isValidate(validateForm, formData?.TenantName) ? "field-invalid" : "field-valid"}`}
                            >
                              <TenantDropdown
                                textField="TenantName"
                                label="Tenant"
                                dataItemKey="TenantId"
                                className="tenant-dropdown k-align-self-end"
                                tenant={tenant}
                                setTenant={setTenant}
                                defaultItem={{
                                  TenantName: "Select Tenant ...",
                                  disabled: true,
                                  OrganizationPrefix: "",
                                }}
                                editMode={state?.edit ? "ReadOnly" : ""}
                                filterable={true}
                                tenantFormId="tenant_id"
                                tenantFormName="TenantName"
                                setFormData={setFormData}
                                requiredField={true}
                              />
                              {isValidate(
                                validateForm,
                                formData?.TenantName
                              ) && <Error>This field cannot be empty</Error>}
                            </div>
                          </div>
                          <div className="k-d-flex single-field-row">
                            <div
                              className={`field-wrapper ${!state?.edit && "prefix-field-wrapper"} ${isValidate(validateForm, formData.les_cmd_id) ? "field-invalid" : "field-valid"}`}
                            >
                              <Field
                                key={"les_cmd_id"}
                                id={"les_cmd_id"}
                                name={"les_cmd_id"}
                                label={"Command Id"}
                                data={formData?.les_cmd_id}
                                onChange={handleInputChange}
                                type={"text"}
                                component={TextInput}
                                renderTypes={state?.edit ? "ReadOnly" : ""}
                                optional={false}
                                requiredField={true}
                                prefix={!state?.edit ? true : false}
                                prefixLabel={tenant?.OrganizationPrefix}
                              />
                              {isValidate(
                                validateForm,
                                formData.les_cmd_id
                              ) && <Error>This field cannot be empty</Error>}
                            </div>
                            <div className={`field-wrapper`}>
                              <Field
                                key={"grp_nam"}
                                id={"grp_nam"}
                                name={"grp_nam"}
                                data={formData?.grp_nam}
                                onChange={handleInputChange}
                                label={"Group Name"}
                                type={"text"}
                                component={TextInput}
                                renderTypes={
                                  !detailsDataModified ? "ReadOnly" : ""
                                }
                                optional={false}
                              />
                            </div>
                          </div>
                          <div className="k-d-flex single-field-row">
                            <div
                              className={`field-wrapper field-wrapper-100  ${isValidate(validateForm, formData?.syntax) ? "field-invalid" : "field-valid"} field-wrapper-cmd`}
                            >
                              <CommandEditor
                                renderTypes={
                                  !detailsDataModified ? "ReadOnly" : ""
                                }
                                validateForm={validateForm}
                                formData={formData}
                                setFormData={setFormData}
                                detailsDataModified={detailsDataModified}
                                isEditor={isEditor}
                                setIsEditor={setIsEditor}
                                editorField={{
                                  name: "syntax",
                                  value: formData?.syntax,
                                  label: "Syntax",
                                }}
                                setEditorField={setEditorField}
                                smHeight={"100px"}
                                lgHeight={"70vh"}
                                requiredField={true}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    />
                  </div>
                </CardBody>
              </Card>
              {detailsDataModified && (
                <Card className="k-mt-3">
                  <CardHeader>
                    <div className="k-d-flex single-field-row button-form-wrapper k-action-buttons !k-px-0">
                      <>
                        <Button
                          icon={"cancel"}
                          onClick={() => onExit()}
                          type="button"
                        >
                          Cancel
                        </Button>
                        <SplitButton
                          text="save"
                          themeColor={"primary"}
                          onButtonClick={() => onSave({ isExit: false })}
                          onItemClick={handleSaveSplit}
                        >
                          <SplitButtonItem text="Save & Close" />
                        </SplitButton>
                      </>
                    </div>
                  </CardHeader>
                </Card>
              )}
            </div>
          </>
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
};
export default AddLCS;
