import { objectToQueryString } from "smart-react";
import { apiCall } from "../../../Middleware/API/apiRequest";
import { getTokensFromStorage } from "../../../Utils/Auth/AuthToken";
import { AUTEST_LCS } from "../../../constants/apiRoutes";

/**
 * list Load Command.
 * @returns {Promise} - A promise that resolves with the list of load command or rejects with an error.
 * @param props
 */
export const listLoadCommand = async ({
  accessToken,
  limit,
  offset,
  filter,
  sort,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const data = {
    Filters: filter,
    pageNumber: offset == 0 ? 1 : offset,
    pageSize: limit,
    sort,
  };

  const response = await loadCommandApiCall({
    url: `${AUTEST_LCS}/filter`,
    method: "POST",
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return {
    loadCommands: response.Payload,
    TotalCount: response?.TotalRecordCount,
  };
};

/**
 * Update Load Command.
 * @returns {Promise} - A promise that resolves with the update of test or rejects with an error.
 * @param data
 */
export const updateLoadCommand = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  let parameters = objectToQueryString({ id: data?.les_cmd_id }); // Simplified object property names
  const response = await loadCommandApiCall({
    url: `${AUTEST_LCS}/${data?.les_cmd_id}`,
    method: "PUT",
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return {
    loadCommands: response.Payload,
    TotalCount: response?.TotalRecordCount,
  };
};

/**
 * Create Command.
 * @returns {Promise} - A promise that resolves with the creation of test or rejects with an error.
 * @param data
 */
export const createCommand = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await loadCommandApiCall({
    url: `${AUTEST_LCS}`,
    method: "POST",
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * Update Command.
 * @returns {Promise} - A promise that resolves with the update of test or rejects with an error.
 * @param data
 */
export const updateCommand = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await loadCommandApiCall({
    url: `${AUTEST_LCS}/${data?.tenant_id}`,
    method: "PUT",
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * Delete Command.
 * @returns {Promise} - A promise that resolves with the update of test or rejects with an error.
 * @param data
 */
export const deleteCommand = async ({
  data,
  moduleName,
  isServiceWorker,
  listController,
}) => {
  const { accessToken } = getTokensFromStorage();
  const response = await loadCommandApiCall({
    url: `${AUTEST_LCS}/${data?.les_cmd_id}?les_cmd_id=${data?.les_cmd_id}&tenantid=${data?.tenant_id}&custlvl=${data?.cust_lvl}`,
    method: "DELETE",
    moduleName,
    isServiceWorker,
    listController,
    body: data,
    accessToken,
  });
  return response;
};

/**
 * User Api Call Wrapper Method.
 * @param {object} api - Contains details like url headers method and data.
 * @returns {Promise} - A promise that resolves with the data of user or rejects with an error.
 */
const loadCommandApiCall = async (request) => {
  let response;
  const headers = {
    "Content-Type": "application/json",
    Accept: "application/json",
  };
  request.headers = headers;
  try {
    response = await apiCall(request);
  } catch (e) {
    response = { isSuccess: false, message: e.message };
  }
  return response;
};
