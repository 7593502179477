import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { Button  } from "@progress/kendo-react-buttons";
import { Loader, ErrorFallback } from "smart-react";
import { buildNotification, GenerateNotification} from 'smart-react';
import { updateLoadCommand } from "../../Services/LoadCommandService";
import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from '../../../../constants/eventDataTypes';
import './Editor.scss';

/**
 * LoadCommands main screen
 * @type {React.FC<Props>}
 * @returns {React.ReactElement} The LoadCommands component.
 */
const Editor = ({syntaxData, setSyntaxData}) => {

  const onChange = React.useCallback((val, viewUpdate) => {
    const editorOBj = syntaxData;
    editorOBj.syntax = val;
    setSyntaxData(editorOBj);
  }, []);
  const notificationMetaData = {
    title: 'Succefully Saved',
    description: 'Your changes have been saved successfully.',
    style: 'success',
  };

  const exit=()=>{
    setSyntaxData("");
  }
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className="container mx-auto">
        <React.Suspense fallback={<Loader />}>
         
      
            <div className="k-border k-m-3 k-p-3 command-syntax-content">
              <CodeMirror value={syntaxData?.syntax} height="70vh" extensions={[javascript({ jsx: true })]} onChange={onChange} />
              <div className="lcs-action">
              <Button themeColor={"primary"} onClick={()=>exit()}>Exit</Button>
              </div>
            </div>
        
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
};
export default Editor;
