import React from 'react';
import { Form, FormElement } from "@progress/kendo-react-form";
import { Button } from "@progress/kendo-react-buttons";
import { Field } from '@progress/kendo-react-form';
import { FormInput,MessageAlert, buildNotification, GenerateNotification } from 'smart-react';
import { apiRequest } from '../../../../Middleware/API/apiRequest';
import { useNavigate } from 'react-router-dom';
import '../../Pages/Splash.scss';
import {
    EVENTS_DATA_TYPES,
    NOTIFICATION_TYPES,
  } from '../../../../constants/eventDataTypes';
const notificationMetaData = {
    title: 'Password Update Successful',
    description: 'Your password has been successfully updated.',
    style: 'success',
  };
const NewPassword = ({setIsLoader}) => {
    const [formState, setFormState] = React.useState({});
    const [formKey, setFormKey] = React.useState(1);
    const queryParams = new URLSearchParams(window.location.search);
    const navigate = useNavigate();
    const token = queryParams.get('token');
    const validatePassword = (value) => {

        if (!value) {
            return "Please enter a value.";
        }
    
        if (value.length < 8) {
            return "Password must be at least 8 characters long.";
        }
    
        // Add more validation rules as needed, e.g., check for uppercase, lowercase, digits, special characters
    
        // Example: check for at least one uppercase letter
        if (!/[A-Z]/.test(value)) {
            return "Password must contain at least one uppercase letter.";
        }
    
        // Example: check for at least one digit
        if (!/\d/.test(value)) {
            return "Password must contain at least one digit.";
        }
    
        // Example: check for at least one special character
        if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
            return "Password must contain at least one special character.";
        }
    
        // All validation rules passed, return empty string to indicate valid input
        return "";
    }

    const validateConfirmPassword = (value,formRenderProps)=> {
       const password =  formRenderProps.valueGetter('password');
       if(value !== password){
        return "Passwords does not match!";
       }
    }
    const [alertMessage, setAlertMessage] = React.useState({
        alert: false,
        message: null,
        type: 'danger',
        iconName: 'error',
      });


    
    const handleSubmit = async (dataItem) => {
       
            setIsLoader(true);
            dataItem = {
                "NewPassword": dataItem?.password,
                "ConfirmPassword":  dataItem?.cpassword,
                "AppId": process.env.APP_ID,
                "Token": token,
                "Active": true,
              };
            const baseURL = `${process.env.BASE_URL}/api/NewPassword/NewPassword`;
            const request = {
                baseURL,
                method: 'POST',
                headers: {
                  'content-type': 'application/json',
                  Accept: '*/*',
                  'X-Application-Id':process.env.APP_ID,
                  'X-App-Key':process.env.APP_KEY,
                  'X-App-Secret':process.env.APP_SECRET,
                },
                body: JSON.stringify(dataItem),
              };
            
            try {
                const response = await apiRequest(request);
                if (!response?.IsSuccess) {
                    
                    setAlertMessage({ alert: true,type:'danger', message: response?.StatusDesc,iconName:"error" });
                   
             
                } else {
                    GenerateNotification(
                        buildNotification(notificationMetaData),
                        NOTIFICATION_TYPES.APP,
                        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
                      );
                      navigate('/login');
                    setFormKey(formKey + 1);
                    setAlertMessage({ alert: true,type:'success', message: "Your password has been successfully updated.",isIcon:false,iconName:'check' });
                }
            } catch (e) {
                console.log(e);
            }
            finally {
                setIsLoader(false);
              }
      
       
    };
    return (
        <>
            {alertMessage?.alert && (
                <MessageAlert
                    type={alertMessage?.type}
                    message={alertMessage?.message}
                    isIcon={true}
                    iconName={alertMessage?.iconName}
                />
            )}

            <Form
                initialValues={formState}
                onSubmit={handleSubmit}
                key={formKey}
                render={(formRenderProps) => (

                    <FormElement>
                        <div>
                         
                            <Field
                                key={'password'}
                                id={'password'}
                                name={'password'}
                                label={'Password'}
                                labelClass={"k-font-bold"}
                                inputClass={'k-login-input'}
                                type={'password'}
                                component={FormInput}
                                optional={false}
                                validator={validatePassword}
                            />
                            <Field
                                key={'cpassword'}
                                id={'cpassword'}
                                name={'cpassword'}
                                label={'Confirm Password'}
                                labelClass={"k-font-bold"}
                                inputClass={'k-login-input'}
                                type={'password'}
                                component={FormInput}
                                optional={false}
                                
                                validator={(e)=>validateConfirmPassword(e,formRenderProps)}
                            />
                            

                        </div>
                        <div
                            style={{
                                justifyContent: "space-between",
                                alignContent: "center",
                            }}
                            className={
                                "k-form-buttons k-button k-button-md k-rounded-md k-button-solid k-button-solid-bases-end"
                            }
                        >
                            <Button className="k-w-100" themeColor={"dark"} type={"submit"}
                                rounded={'medium'} size={'medium'} disabled={!formRenderProps.allowSubmit}>
                                Create Password
                            </Button>
                         
                         
                        </div>
                       
                    </FormElement>
                )}
            />
        </>
    );
}
export default NewPassword;
