import * as React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { apiRequest } from "../../../Middleware/API/apiRequest";
import { callApi } from "./../../../Utils/Api/Api";
import { useAuth } from "../../Core/Context/AuthContext";
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  TOKEN_EXP,
  USER_DATA,
} from "../../../constants/applicationConstants";
import { SOCIAL_MEDIA_LOGIN } from "../../../constants/apiRoutes";

import { errorGenerator, ErrorFallback } from "smart-react";
import { dexieCore } from "../../../Utils/Storage/IndexDB/dexieDb";
import { setUserTenant } from "../Utils/CommonUtil";
const dashboardPath = "/products";
/**
 * Handle Redirection and User Data on Redirection
 */
const SocialMediaRedirectComponent = () => {
  const navigate = useNavigate();
  const { updateUser } = useAuth();

  /**
   * Used to get the Tokens
   */
  useEffect(() => {
    // get auth code from url

    const urlParams = new URLSearchParams(window.location.search);
    const redirectAuthCode = urlParams.get("code");
    // get access token with formatted body
    getTokens(generateFormBody(redirectAuthCode));
  }, []);

  // generate form body for request to token endpoint
  function generateFormBody(authCode) {
    const details = {
      code: authCode,
      authGatewayType: "Google",
    };

    return details;
  }

  // send request for id, access and refresh tokens
  async function getTokens(formBodyDetails) {
    // Default options are marked with *

    const data = await getTokenFromAPI(formBodyDetails);

    if (!data?.IsSuccess) {
      errorGenerator({
        title: "Data not Valid",
        message: data?.StatusDesc,
      });
      navigate("/");
    } else if (data) {
      const parts = data?.Payload?.UserName?.split(",");
      const username = parts[0];
      const password = parts[1];
      const dataItem = {
        grant_type: "password",
        AppId: process.env.APP_ID,
        device_id: navigator.userAgent,
        browser: navigator.userAgent,
        username: username,
        password: password,
        os: navigator.platform,
        SrcTypeId: "Google",
      };
      const encodedData = Object.keys(dataItem)
        .map(
          (key) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(dataItem[key])}`
        )
        .join("&");
      try {
        let response = await callApi("/token", "POST", encodedData);

        if (response?.error) {
          errorGenerator({ alert: true, message: data?.error_description });
        } else {
          const data = response;

          sessionStorage.setItem(ACCESS_TOKEN, data.access_token);
          sessionStorage.setItem(REFRESH_TOKEN, data.refresh_token);
          sessionStorage.setItem(TOKEN_EXP, data.expires_in);
          sessionStorage.setItem(USER_DATA, JSON.stringify(data));
          let configs = [
            {
              ConfigId: ACCESS_TOKEN,
              value: data.access_token,
            },
            {
              ConfigId: REFRESH_TOKEN,
              value: data.refresh_token,
            },
          ];
          await dexieCore.Configurations.bulkPut(configs);
          updateUser(data);
          let responseData = await setUserTenant(data);
          window.location.replace(dashboardPath);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }

  /**
   * Call KOCHID API to authenticate user
   * @async
   * @param {*} formBodyDetails
   * @returns {Promise<object>} - A promise that resolves the token object.
   */
  async function getTokenFromAPI(formBodyDetails) {
    const baseURL = `${process.env.BASE_URL}/${SOCIAL_MEDIA_LOGIN}?code=${formBodyDetails?.code}&authGatewayType=${formBodyDetails?.authGatewayType}`;

    const request = {
      baseURL,
      method: "GET",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        Accept: "*/*",
        "X-Application-Id": process.env.APP_ID,
      },
    };
    const data = await apiRequest(request);
    return data;
  }

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <p>redirecting...</p>
    </ErrorBoundary>
  );
};

export default SocialMediaRedirectComponent;
