import { DataColumns } from "../DataColumns/DataColumns";

/**
 * Generates a list of columns for a grid.
 * @param {object} options - The options for the grid.
 * @returns {Array} - The list of grid columns.
 */
export const GridColumnsList = async ({ onSyntaxClick, editScreen }) => {
  return DataColumns({ onSyntaxClick, editScreen });
};

export default GridColumnsList;
