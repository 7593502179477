import * as React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import logo from '../../../assets/images/sm/logo-login.png';
import logoDark from '../../../assets/images/sm/logo-login.png';
import { DEVICE_TYPES } from '../../../constants/eventDataTypes';
import { GlobalContext } from 'smart-react';
import {ErrorFallback,Loader} from 'smart-react';
import { Typography } from "@progress/kendo-react-common";
import LoginWithAzure from '../Components/Buttons/LoginWithAzure';
import LoginWithGoogle from '../Components/Buttons/LoginWithGoogle';
import TenantForm from '../Components/Forms/TenantForm';
import { MessageAlert } from 'smart-react';
import { Link } from 'react-router-dom';
import './Splash.scss';

/**
 * Main Login Screen
 * @returns {React.Element} returns main login screen
 */
const UserTenant = () => {
  const { dimensions, deviceInfo } = React.useContext(GlobalContext);
  const [isMobile, setIsMobile] = React.useState(null);
  const [isLoader, setIsLoader] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const loginGridClassName = `login-grid`;

  React.useEffect(() => {
    if (dimensions !== undefined) {
      if (
        deviceInfo?.type === DEVICE_TYPES.PHONE ||
        deviceInfo?.type === DEVICE_TYPES.TABLET
      ) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
  }, [dimensions]);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <>
      {isLoader && <div className="loader-wrapper">
                    <Loader />
       </div>}
        {isMobile !== null && (
          <>
            {!isMobile ? (
              <div className={loginGridClassName}>
                <div className="login-rside">
                  <img
                    src={logo}
                    className="login-logo"
                    alt={`${process.env.APP_NAME} Logo`}
                  />
                  <h2>SMART APPS</h2>
                </div>

                <div className="login-fside">
                  <div className='form-container'>
                    <Typography.h6 className='k-font-bold' fontWeight='bold' textAlign='center'>Select Your Tenant</Typography.h6>
                    {errorMessage && (
                <MessageAlert
                    type={'danger'}
                    message={errorMessage}
                    isIcon={true}
                    iconName={'error'}
                />
            )}
                    <TenantForm isLoader={isLoader} setIsLoader={setIsLoader}/>
                  
                   
                  </div>
                </div>
              </div>
            ) : (
              <div className="login-grid-mobile">
                <div className="login-mobile">
                  <img
                    src={logoDark}
                    className="login-logo-mobile"
                    alt={`${process.env.APP_NAME} Logo`}
                  />
                  <div className='form-container-mobile'>
                    <Typography.h6 className='k-font-bold' fontWeight='bold' textAlign='center'>Select Your Tenant</Typography.h6>
                    <TenantForm isLoader={isLoader} setIsLoader={setIsLoader} />
                  
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </>
    </ErrorBoundary>
  );
};

export default UserTenant;
