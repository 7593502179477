import { apiRequest } from "../../../Middleware/API/apiRequest";
import {
  USER_TENANTS,
  DEFAULT_TENANT,
  TENANT_ID,
} from "../../../constants/applicationConstants";
/**
 * set User Tenant
 * @param data
 * @returns {FilterArray} returns filter array
 */
export const setUserTenant = async (data) => {
  try {
    const baseURL = `${process.env.BASE_URL}/api/Applications/Users/${data.userId}/Tenants`;
    const request = {
      baseURL,
      method: "GET",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
        Accept: "*/*",
        "X-Application-Id": process.env.APP_ID,
        "X-App-Key": process.env.APP_KEY,
        "X-App-Secret": process.env.APP_SECRET,
        Authorization: `Bearer ${data.access_token}`,
      },
    };
    const responseData = await apiRequest(request);
    localStorage.setItem(USER_TENANTS, JSON.stringify(responseData.Payload));
    let userTenants = responseData?.Payload.filter(
      (item) => item.IsWrite && item.IsDefault
    );
    localStorage.setItem(DEFAULT_TENANT, JSON.stringify(userTenants[0]));
    localStorage.setItem(TENANT_ID, userTenants[0]?.TenantId.toString());
    return;
  } catch (error) {
    return;
  }
};
